export const round4Instructions = [
  {
    title: "Round 4 Instructions",
    desc: [
      {
        desc: [
          "Click on the <span>'View Assignment'</span> to access the coding project.",
          "Read the project description and requirements thoroughly.",
          "Be mindful of the time limit specified for the assessment.",
          "Manage your time efficiently to address all aspects of the project.",
          "Ensure you understand the scope, functionality, and any specific instructions.",
          "Follow industry-standard coding conventions and best practices.",
          "Document your code for clarity and maintainability.",
          "Upload the code in a zip file including all the assets and libraries used in the project.",
          "You can upload the solution any number of times till the time expires. Your last uploaded file will be automatically submitted for evaluation on time expiration.",
          "Your submission will be evaluated based on correctness, efficiency, code quality, and adherence to the requirements.",
          "Pay attention to edge cases and error handling.",
        ],
      },
    ],
  },
  {
    title: "General Instructions",
    desc: [
      {
        subtitle: "Before you start the test",
        desc: [
          "Please make sure you have all required software are installed before you start the test.",
          // "Once you start the project, it must be completed in one session. There is no option to abort the test and resume at a later time.",
          "Project should be completed within 24 hrs.",
          "You can upload multiple files. Latest/last file will be considered for the evaluation.",
          "As soon as you begin the test, you will have access to the questions, and the timer will start.",
        ],
      },
      {
        subtitle: "During the test",
        desc: [
          " If you encounter any problem accessing or submitting the test or require any further clarifications regarding the assignment write to us at <span>assessment@zeero.us.</span>",
        ],
      },
    ],
  },
];

export const round4InstructionsDataScience = [
  {
    title: "Round 4 Instructions",
    desc: [
      {
        desc: [
          "Click on the <span>'View Assignment'</span> to access the case study.",
          "Read the case study and requirements thoroughly.",
          "Be mindful of the time limit specified for the assessment.",
          "Manage your time efficiently to address all aspects of the project.",
          "Ensure you understand the scope, functionality, and any specific instructions.",
          "Follow industry-standard coding conventions and best practices.",
          "Document your code for clarity and maintainability.",
          "Upload the code in a zip file including all the assets and libraries used in the project.",
          "You can upload the solution any number of times till the time expires. Your last uploaded file will be automatically submitted for evaluation on time expiration.",
          "Your submission will be evaluated based on correctness, efficiency, code quality, and adherence to the requirements.",
          "Pay attention to edge cases and error handling.",
        ],
      },
    ],
  },
  {
    title: "General Instructions",
    desc: [
      {
        subtitle: "Before you start the test",
        desc: [
          "Please make sure you have all required software are installed before you start the test.",
          // "Once you start the project, it must be completed in one session. There is no option to abort the test and resume at a later time.",
          "Project should be completed within 24 hrs.",
          "You can upload multiple files. Latest/last file will be considered for the evaluation.",
          "As soon as you begin the test, you will have access to the questions, and the timer will start.",
        ],
      },
      {
        subtitle: "During the test",
        desc: [
          " If you encounter any problem accessing or submitting the test or require any further clarifications regarding the assignment write to us at <span>assessment@zeero.us.</span>",
        ],
      },
    ],
  },
];

