import { clientQues } from "./clientQuestions";
import clientImage1 from "../Assets/home_client/client_1.webp";
import weOffer1 from "../Assets/home_client/Group_1.svg";
import weOffer2 from "../Assets/home_client/Group_2.svg";
import weOffer3 from "../Assets/home_client/Group_3.svg";
import weOffer4 from "../Assets/home_client/Group_4.svg";
import weOffer5 from "../Assets/home_client/Group_5.svg";
import weOffer6 from "../Assets/home_client/Group_6.svg";

export const home2Data = {
  breadCrumbs: [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Client",
      link: "/client",
    },
  ],
  first: {
    heading: "",
    data: [
      {
        title: "BUILD, MANAGE, AND SCALE SOFTWARE DEVELOPMENT TEAMS WITH EASE",
        desc: "At Zeero, we provide a comprehensive suite of solutions designed to streamline the hiring process, drive innovation, and accelerate business growth. ",
        buttons: [
          {
            title: "Hire Now",
            link: "/contact/client",
            backgroundColor: "#34A853",
          },
        ],
        imgLink: clientImage1,
      },
    ],
  },
  weoffer: {
    header: "What We Offer ?",
    data: [
      {
        imgLink: weOffer1,
        title: "Access to Top Talent",
        desc: "Access top-notch software developers on-demand for your projects. Our developers undergo extensive tests, project submissions, and interviews, ensuring their expertise in the industry.         ",
      },
      {
        imgLink: weOffer2,
        title: "Flexibility and Scalability",
        desc: "Whether you prefer short-term contracts, long-term partnerships, remote or onsite work arrangements, Zeero provides customizable solutions for maximum flexibility and scalability.",
      },
      {
        imgLink: weOffer3,
        title: "Cost-Effective Solutions",
        desc: "Save time and resources with Zeero's efficient recruitment and onboarding process allowing businesses to focus on driving innovation and growth.",
      },
      {
        imgLink: weOffer4,
        title: "Strategic Partnership",
        desc: "We go beyond simply connecting you with talent. We're your strategic partner, supporting you as a solution to the challenges you face while resource planning.",
      },
      {
        imgLink: weOffer5,
        title: "Diverse and Inclusive Teams",
        desc: "Build stronger, more inclusive teams with Zeero's global talent pool, accessing a wide range of perspectives and experiences to promote creativity, innovation, and collaboration.",
      },
      {
        imgLink: weOffer6,
        title: "Project Management & 24X7 Support",
        desc: "Our support staff is always on hand to ensure you get exactly what you want for your projects. We provide you with 24x7 support and guidance you need to succeed.",
      },
    ],
    button: {
      text: "Hire Talent",
      link: "/contact/client",
    },
  },
  third: {
    heading: "",
    data: [
      {
        title: "",
        desc: "On-Demand Access to Top-Tier Talent: Our on-demand talent pool gives you access to the best software developers in the industry, ready to work on your projects whenever you need them. Our developers go through over 8 hours of tests, project submissions and interviews.",
        buttons: [
          {
            title: "Hire Talent",
            link: "/contact/client",
          },
          {
            title: "Apply as Developer",
            link: "/login",
          },
        ],
        imgLink:
          "https://hips.hearstapps.com/hmg-prod/images/close-up-of-tulips-blooming-in-field-royalty-free-image-1584131603.jpg?crop=1.00xw:0.798xh;0,0.202xh&resize=1200:*",
      },
    ],
  },
  ClientConfession: {
    heading: "what our clients say",
    desc: "Neque, pulvinar vestibulum non aliquam.",
    data: [
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
      {
        noOfStars: 5,
        title:
          "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
        pic: "https://cdn.dribbble.com/users/2878951/screenshots/14013747/media/603f0b853c409547dfa51cba996f375c.png?compress=1&resize=400x300",
        exp: "Courtney Henry, Google",
      },
    ],
  },
  howToHire: {
    header: "How to hire a developer?",
    explanation: [
      {
        img: "https://hips.hearstapps.com/hmg-prod/images/close-up-of-tulips-blooming-in-field-royalty-free-image-1584131603.jpg?crop=1.00xw:0.798xh;0,0.202xh&resize=1200:*",
        title: "Define Your Requirements",
        desc: "Share your requirements. If unsure, connect with our experts for guidance ",
      },
      {
        img: "https://hips.hearstapps.com/hmg-prod/images/close-up-of-tulips-blooming-in-field-royalty-free-image-1584131603.jpg?crop=1.00xw:0.798xh;0,0.202xh&resize=1200:*",
        title: "Discover Suitable Developers",
        desc: "We will share a customized list of pre-vetted developers that match your requirements ",
      },
      {
        img: "https://hips.hearstapps.com/hmg-prod/images/close-up-of-tulips-blooming-in-field-royalty-free-image-1584131603.jpg?crop=1.00xw:0.798xh;0,0.202xh&resize=1200:*",
        title: "Select and Onboard ",
        desc: "Shortlist and onboard the developer you prefer ",
      },
    ],
    // ctalink: {
    //   text: "See it in action",
    //   link: "#",
    // },
    button: {
      text: "Hire Talent",
      link: "/contact/client",
    },
  },
  howToHire2: {
    heading: "How to hire a developer? ",
    title:
      "Get started in just 5 steps...",
    // desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    button: {
      title: "Hire Now",
      link: "/contact/client",
    },
    // cta: {
    //   title: "Watch more",
    //   link: "#",
    // },
    rightData: [
      {
        title: "Define Your Needs",
        desc: "Tell us about your project's goals, timeline, and skill requirements. Our Client Success Manager will work with you to ensure a clear understanding.",
      },
      {
        title: "Match with Top Talent",
        desc: "We tap into our pre-vetted pool of developers to find the perfect fit. We consider both technical skills and cultural alignment for successful collaboration.",
      },
      {
        title: "Review and Select",
        desc: "Our Client Success Manager will present you with curated profiles that match your needs. You can then review and select the developers who best fit your project.",
      },
      {
        title: "Smooth Onboarding",
        desc: "Once you've chosen your team, Zeero handles the onboarding process, ensuring a smooth transition for both you and the developers.",
      },
      {
        title: "Start Your Project with Confidence",
        desc: "Your dedicated team is ready to begin collaborating on your project.Zeero provides ongoing support to ensure success.",
      },
    ],
  },
  faqs: {
    title: "Frequently Asked Questions",
    // desc: "Pellentesque cras adipiscing tempus libero vel nullam mauris tellus. Aliquam ultrices tellus consequat amet, lectus aliquam est in neque.",
    mainData: [
      {
        questions: clientQues,
      },
    ],
  },
};
