import "./Blogs.scss";
import doubleArrow from "../../../Assets/home/doubleArrowRight.svg";
export const BlogsMain = ({ ComponentData }) => {
  return (
    <div className="blogs-main">
      <div className="title">{ComponentData?.title}</div>
      <div className="blog-cards-container">
        {ComponentData?.data?.map((item, index) => (
          <BlogCard key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

const BlogCard = ({ data }) => {
  const { image, title, description, date, readTime, link } = data;

  return (
    <div className="blog-card">
      <div className="blog-card-image">
        <img src={image} alt={title} />
      </div>
      <div className="blog-card-content">
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
        <div className="blog-card-footer">
          <div className="date-read-time">
            <div className="date">{date}</div>
            <div className="dot"></div>
            <div className="read-time">{readTime}</div>
          </div>
          <a href={link} className="read-more" target="_blank">
            <span>Read more</span>
            <span>
              <img src={doubleArrow} alt="" />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};
