// src/Banner.js
import React from "react";
import "./Banner.scss";
import backgroundImage from "../../../Assets/home/CTABanner.webp";
import { useNavigate } from "react-router-dom";

export const Banner = () => {
  const navigate = useNavigate();
  return (
    <div
      className="banner"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="banner-content">
        <div className="left-content">
          <h2>Unlock Top Talent – Partner Now!</h2>
        </div>
        <div className="right-content">
          <p>
            Ready to build, manage, and scale your tech teams with top talent?
            Find your perfect software developer now.
          </p>
          <button
            className="get-started-button"
            onClick={() => navigate("/contact/client")}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};
